#footer-sole{
  
  background: black;
  color: $color-white;
 
  overflow: hidden;
  padding: 40px 10px;

  @include media-query($medium-up) {
    padding: 40px 0px 0px 0px;
  }
  width: 100%;
  & .logos a {
    height: 35px;
    & img {
      height: 25px;
    }
  }
  
  & .social-icons a {
    & svg {
      fill: white !important;
      height: 25px;
      width: 25px;
      &:hover {
        fill: #848484 !important;
      }
    }
  }
  p, a{
    color: $color-white;
  }
  

  .btn{
    text-transform: capitalize;
  }

  ul, li{
    margin: 0 15px 0 0;
    list-style: none;
    font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: $color-white;
    display: inline-block;
    vertical-align: middle;
    a{
      font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
      display: inline-block;
      height: 35px;
    }
  }

  ul li:last-child {
    margin-right: 0;
  }

  ul{
    margin-top: 33px;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-col-4{
    text-align: center;
  }

  .footer-logos{
    padding-top: 40px;
 

    @include media-query($medium-up) {
        padding-bottom: 0
    }

    a {
      // @extend .col;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      @include media-query($medium-up) {
        
      }

      &:hover {
        text-decoration: underline;
      }

      &.recork{
      

        @include media-query($medium-up) {
          order: 12
        }
      }
    }
    img{
      height: 42px;
      width: auto;
      margin-bottom: 60px;
      
      @include media-query($medium-up) {
        height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  input[type="text"], input[type="email"]{
    width: 100%;
    color: grey;
    line-height: 31px;
    padding-left:10px;
    font-size: $font-base;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .btn-default{
    background: black;
    border: 2px solid white;
    border-radius: 0;
    color: $color-white;
    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    height: 100%;
  }

  h5{
    font-size: 14px;
    font-family: 'GloberSemiBold', Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    padding-bottom: 8px;
  }
}

.footer-credits{
  font-size: 11px;
  line-height: 20px;

  a, p{
    color: $color-white;
    font-size: 11px;
    line-height: 20px;
  }
  p{
    display: inline-block;
  }
  @include media-query($medium-up) {
    p{
      margin: 0 0 30px 0;
    }
  }
  @include media-query($large-up) {
    p{
      display: flex;
    }
  }

  a{
    padding: 0 8px;
  }


  &-logo{
    display: inline-block;
    margin-right:15px;
    vertical-align: middle;
    margin-bottom: 2px;
    @include media-query($medium-up) {
      margin-right:15px;
    }
  }
 
  &-content{
    &--brand{
      margin-left: 0;
      @include media-query($large-up) {
        margin-left: -$grid-gutter-width;
      }
    }

  }
}
