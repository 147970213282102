@import "bootstrap";
@import "reset";
@import "partials/variables";
@import "partials/mixins";
@import "partials/color";
@import "partials/fonts";
@import "~NodeModules/swiper/swiper-bundle.min.css";
// @import "extras";
// @import "panels";
@import "blogfooter";
// @import "other";
@import "buttons";
@import "layout";
@import "social";
@import "_product.blog.scss";
@import "colors";

$color-text-grey: #848280;
$color-light-rey: #f2f2f2;
$padding-vertical: 15px;
$font-size-base: 18px;

*
{
    will-change: unset !important;
}

@mixin vertical-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin padding-vertical($number: 1) {
  padding-top: $padding-vertical * $number;
  padding-bottom: $padding-vertical * $number;
}

#blog-navbar {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  &.bg-dark {
    background: $SOLE-black !important;
  }
  .nav-item {
    line-height: 27px;
    font-size: 1rem;
    text-transform: capitalize;
  }
  
  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-collapse ~ .navbar-toggler {
    .bars-icon {
      display: block;
    }
    .x-icon {
      display: none;
    }
  }

  .navbar-collapse.show ~ .navbar-toggler,
  .navbar-collapse.collapsing ~ .navbar-toggler {
    .bars-icon {
      display: none;
    }
    .x-icon {
      display: block;
    }
  }
}

.blog-template {
  margin-bottom: 0;
}

.carousel-caption {
  h3 {
    font-size: 59px;
  }
  @include media-query($medium) {
    top: 30px;
    h3 {
      font-size: 40px;
    }
  }
}

p {
  margin: 10px 0 20px 0;
  font-family: "GloberRegular", Arial, Helvetica, sans-serif;

  strong {
    font-family: 'GloberBold', Arial, Helvetica, sans-serif;
  }
}

.breadcrumb {
  padding: $padding-vertical * 2 0 $padding-vertical 0;
  @include media-query($medium-up) {
    @include padding-vertical;
  }
  ul {
    margin-bottom: 0;
    li {
      display: inline-block;
      line-height: 1;
      font-size: $font-size-base * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base;
      }
      font-weight: bolder;
      padding-right: 5px;
      margin-bottom: $padding-vertical * 0.5;
      a {
        line-height: 1;
        font-size: $font-size-base * 0.75;
        @include media-query($medium-up) {
          font-size: $font-size-base;
        }
        &:after {
          content: ">";
          display: inline-block;
          padding: 0 5px;
        }
      }
    }
  }
}

.text-small {
  font-size: ($font-size-base - 2) * 0.75;
  @include media-query($medium-up) {
    font-size: $font-size-base - 2;
  }
  text-decoration: underline;
}

.slider {
  &-wrapper {
    img {
      width: 100%;
    }
  }
}

#footer input[type="email"] {
  width: 100%;
  color: grey;
  line-height: 34px;
  padding-left: 10px;
}

p.slider-caption {
  padding: 0;
}

a {
  &:hover {
    text-decoration: none;
  }
}

div#at4-share {
  @include media-query($medium-up) {
    left: 30px !important;
    z-index: 1;
  }
}

.logoTemp {
  font-size: 18px;
  display: inline-block;
  &:hover {
    svg {
      .stlog {
        fill: #848280;
      }
    }
  }
  svg {
    height: 55px;
  }
}

.blog-aside {
  li.swiper-slide {
    list-style: none;
  }
}

.blog {
  @include padding-vertical;
  // overflow: hidden;
  .sole-quote p {
    font-family: "GloberSemiBold", Arial, Helvetica, sans-serif;
    font-size: $font-size-base * 2 * 0.75;
    line-height: 1.3;
    @include media-query($medium-up) {
      font-size: $font-size-base * 2;
      line-height: 1.3 * 1.25;
    }
    margin-bottom: 30px;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
    @include media-query($small) {
      font-size: 30px;
    }
  }
  &-icon-readMore {
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%; /* future proofing */
    -khtml-border-radius: 50%; /* for old Konqueror browsers */
    padding: 18px;
    border: solid 3px $color-white;
    width: 62px;
    height: 62px;
  }
  &-toggler-icon {
    display: block;
    width: 40px;
    margin-left: 15px;
    height: 20px;
    float: right;
    overflow: hidden;
  }
  &-related {
    padding: 0 0 $padding-vertical 0;
    background: $color-light-rey;
    margin-bottom: $padding-vertical * 1.5;
    @include media-query($medium-up) {
      background: none;
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }
    &__copy {
      img {
        width: 100%;
      }
    }
    &__button {
      @include media-query($small) {
        display: block;
        color: $color-white;
        text-decoration: none;
        font-size: ($font-size-base + 3) * 0.75;
        @include media-query($medium-up) {
          font-size: $font-size-base + 3;
        }
        background: $color-black;
        padding: $padding-vertical;
        text-align: center;
        font-weight: bold;
      }
    }
  }
  &-hero {
    background-size: cover;
    background-position: center;
    background-repeat: none;
    height: 500px;
    @include media-query($medium-up) {
      height: 450px;
    }
  }
  .SoleArticleSection {
    padding-top: 15px;
    @include media-query($medium-up) {
      width: 70%;
      margin-left: 15%;
    }
  }
  &-article {
    .Blog__img-figure {
      width: 84%;
      padding-left: 8%;
      p {
        color: #797979 !important;
        font-weight: bold;
        font-family: "GloberSemiBold", Arial, Helvetica, sans-serif;
        font-size: $font-size-base * 0.75;
        @include media-query($medium-up) {
          font-size: $font-size-base;
        }
        a {
          b {
            font-family: "GloberBold", Arial, Helvetica, sans-serif !important;
          }
          font-family: "GloberSemiBold", Arial, Helvetica, sans-serif !important;
          font-weight: bold !important;
          color: #797979 !important;
          &:hover {
            color: black !important;
            text-decoration: none !important;
          }
        }
        b {
          a {
            font-family: "GloberBold", Arial, Helvetica, sans-serif !important;
          }
        }
      }
    }
    .blog-image-wrapper {
      position: relative;
      margin: 0 10px;
      @include media-query($medium-up) {
        margin-right: -70px;
        margin-left: -70px;
        margin-top: 20px;
        margin-bottom: $padding-vertical * 2;
      }
      img {
        width: 100%;
      }
    }
    .blog-header {
      margin-bottom: 0;
      color: black;
    }
    .blog-image-button {
      position: absolute;
      top: 36px;
      bottom: 0;
      right: 25px;
      display: inline-block;
      background: $color-white;
      padding: 10px 40px;
      line-height: 1;
      height: 35px;
      border: solid 1px black;
      text-align: center;
      font-size: 18px;
      @include media-query($small) {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
    .blog-image-wrapper {
      @include padding-vertical(0.7);
    }
    ul,
    li {
      font-family: "GloberRegular", Arial, Helvetica, sans-serif;
      margin-top: 10.5px;
      margin-bottom: 10.5px;
      font-size: 16px;
      line-height: 28px;
    }
    p {
      margin-top: 17.5px;
      margin-bottom: 17.5px;
      font-size: ($font-size-base + 6) * 0.75;
      line-height: 1.5;
      @include media-query($medium-up) {
        font-size: $font-size-base + 2;
        line-height: 1.5 * 1.25;
      }
      font-family: "GloberRegular", Arial, Helvetica, sans-serif;
      display: block;
      color: black;
      a,
      a:link,
      a:visited {
        font-family: "GloberBold", Arial, Helvetica, sans-serif;
        color: #0071d1;
        text-decoration: none;
        &:hover {
          color: $color-link;
          text-decoration: underline;
        }
      }
    }
    p.blog-meta {
      padding: 0;
      margin: 0;
      color: black;
      font-size: ($font-size-base - 2) * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base - 2;
      }
      font-family: "GloberBold", Arial, Helvetica, sans-serif;
    }
    p.slider-caption {
      padding: 0;
      padding-bottom: $padding-vertical * 0.5;
      color: $color-text-grey;
      font-size: ($font-size-base - 1) * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base - 1;
      }
    }
  }
  &-tags {
    margin: $padding-vertical * 3 0 $padding-vertical * 2 0;
    @include media-query($medium-up) {
      margin: $padding-vertical * 4 0 $padding-vertical * 3 0;
    }
    padding: 0;
    li {
      @extend .mb-2, .mr-1, .mt-1, .ml-0;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      a {
        font-weight: normal;
        font-size: 14px;
        background: $color-light-rey;
        padding: 10px $padding-vertical;
        text-transform: uppercase;
        &:hover {
          background: darken($color-light-rey, 4%);
        }
      }
    }
  }
  h3,
  h2,
  h4,
  h5 {
    font-family: "GloberBold", Arial, Helvetica, sans-serif;
    line-height: 1.3;
    text-transform: inherit;
    margin: 10px 0;
    color: $black;
  }
  .h3,
  h3 {
    @extend h3;
    font-size: 1.5em;
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
  &-header {
    @include padding-vertical;
    padding-bottom: 10px;
    font-size: 35px;
    line-height: 1.2;
    color: $color-black;
    font-weight: inherit;
    text-transform: initial;
    font-family: "GloberBold", Arial, Helvetica, sans-serif;
    text-transform: none;
    @include media-query($medium-up) {
      font-size: 44px;
    }
    span {
      border: none;
    }
  }
  &-navigation {
    border-top: solid 1px black;
    margin-top: $padding-vertical * 2;
    padding-top: 5px;
    a {
      text-decoration: none;
    }
  }
  &-aside {
    padding-left: 0px;
    @include media-query($small) {
      padding-left: 0px;
    }
    &__header {
      @include padding-vertical;
      font-size: ($font-size-base * 2) * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base * 2;
      }
      text-align: center;
      color: #2b2823;
      text-transform: initial;
    }
    &__date {
      font-size: $font-size-base * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base;
      }
      line-height: 1.75;
      color: black;
      order: 1;
      margin-top: 10px;
      line-height: 1.4;
      margin-top: 5px;
    }
    ul {
      margin-bottom: -3px;
    }
    &__categoryContent {
      @include media-query($small) {
        display: none;
      }
    }
    & .blogIndex-post__content {
      padding: 0.25rem;
      &-category {
        padding-top: 10px;
        text-transform: uppercase;
        font-family: "GloberSemiBold", Arial, Helvetica, sans-serif;
        margin-bottom: 12px;
        font-size: 13px;
      }
    }
    &__category {
      padding-top: 10px;
      text-transform: uppercase;
      font-family: "GloberSemiBold", Arial, Helvetica, sans-serif;
      margin-bottom: 12px;
      font-size: 13px;
    }
    &__title {
      line-height: 1.19;
      order: 2;
      font-size: ($font-size-base * 1.5) * 0.75;
      text-transform: initial;
      font-family: "GloberBold", Arial, Helvetica, sans-serif;
      margin: 0;
      a:hover {
        color: $color-link;
      }
      @include media-query($medium-up) {
        font-size: $font-size-base * 1.5;
        line-height: 1.2;
      }
    }
  }
}

.ql-editor h3 {
  @extend h3;
}

#blog-content {
  .blogIndex-post__content {
    p {
      font-size: ($font-size-base - 2) * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base - 2;
      }
      padding: 0;
    }
  }
  .blogIndex-post__article-Image-link {
    line-height: 0px;
  }
}


.blogIndex {
  background: $grey-light;
}

.blogIndex, .blog-aside {
  padding: 0 60px 60px 60px;
  overflow: hidden;
  @include media-query($small) {
    padding: 0;
  }
  .sort-options li:first-child {
    text-decoration: underline;
  }
  h2.type-header {
    font-size: $font-size-base * 2 * 0.75;
    line-height: 1.2 * 0.75;
    @include media-query($medium-up) {
      font-size: $font-size-base * 1.5;
      line-height: 1.2;
    }
  }
  div.type-header {
    margin-bottom: -40px !important;
  }
  &-hero {
    &-overlay {
      &-main-content {
        @include media-query($medium-down) {
          margin-top: 0;
          padding-top: 3rem;
          padding-bottom: 3rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
        a:hover {
          text-decoration: none;
        }
      }
    }
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: none;
    min-height: 500px;
    &-featuredBtn {
      .h3,
      p {
        font-family: "GloberBold", Arial, Helvetica, sans-serif;
        font-size: $font-size-base * 1.44 * 0.75;
        @include media-query($medium-up) {
          font-size: $font-size-base * 1.44;
        }
      }
      .readMorefeaturedBtn p {
        font-size: $font-size-base * 1.17 * 0.75;
        @include media-query($medium-up) {
          font-size: $font-size-base * 1.17;
        }
        text-decoration: underline;
      }
    }
    &-readMore {
      &-container {
        position: absolute;
        bottom: 20px;
        width: 100%;
      }

      @include media-query($medium-up) {
      }
      & a {
        color: $color-white;
      }
    }
    h1 {
      font-weight: inherit;
      font-family: "GloberBold", Arial, Helvetica, sans-serif;
      font-size: $font-size-base * 2.56 * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base * 2.56;
      }
    }
    h1,
    p {
      color: $color-white;
      margin: 0 auto;
      @include media-query($medium-up) {
        max-width: 680px;
        width: 80%;
      }
    }
    p,
    a {
      font-size: $font-size-base * 2 * 0.75;
      @include media-query($medium-up) {
        font-size: $font-size-base * 2;
      }
      display: inline-block;
    }
    p {
      margin-bottom: $grid-gutter-width * 0.4;
      margin-top: $grid-gutter-width * 0.4;
    }
    h1 {
      font-weight: bolder;
      margin: $padding-vertical * 1.5 auto;
      line-height: 1;
      font-size: $font-size-base * 3 * 0.75;
      max-width: inherit;
      @include media-query($medium-up) {
        font-size: $font-size-base * 3;
      }
    }
    @include media-query($large-up) {
      height: 800px;
      height: 70vh;
    }
  }
  &-hero-overlay {
    @extend .blogIndex-hero;
    position: relative;
    padding: 20px;
    max-width: 100%;
  }
  &-post {
    &__image {
      width: 100%;
      margin: 0;
      @include media-query($small) {
        background-size: cover;
      }
      .black &,
      .color & {
        display: none;
      }
    }
    &__article {
      margin-bottom: 20px;
      width: 31.9%;
      &:hover {
        .blogIndex-post__image {
          background-size: auto 103%;
        }
        .blogIndex-post__content {
          opacity: 0.8;
        }
      }
      @include media-query($medium) {
        width: 48%;
      }
      @include media-query($small) {
        width: 100%;
      }
      h3 {
        border: none;
      }
      &-Image-link {
        display: block;
        min-height: 150px;
      }
    }
    &__content {
      padding: $padding-vertical * 2;
      background: $color-white;
      a:hover {
        color: $color-link;
        text-decoration: none;
      }
      &-category {
        font-size: $font-size-base * 1.16666666 * 0.75;
        @include media-query($medium-up) {
          font-size: $font-size-base * 1.16666666;
        }
        margin-bottom: $grid-gutter-width * 0.5;
        font-family: "GloberSemiBold", Arial, Helvetica, sans-serif;
        text-transform: uppercase;
      }
      &-header {
        font-size: 34px;
        line-height: 1.23;
        font-family: "GloberXBold", Arial, Helvetica, sans-serif;
        margin-bottom: 10px;
        text-transform: none;
      }
      &-copy {
        font-size: 16px;
        line-height: 1.6;
      }
      .black &,
      .color & {
        background: #2d2820;
        p {
          display: none;
        }
        h3 {
          color: $color-white;
          line-height: 1.2;
          margin-bottom: $padding-vertical;
        }
        &:after {
          content: "Read the story";
          display: block;
          color: $color-white;
          font-size: 12px;
          font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
          text-decoration: underline;
        }
        .blogIndex-post__image {
          display: none;
        }
      }
      .color & {
        background: #f4c917;
      }
    }
  }
}

.blog {
  .btn {
    box-sizing: border-box;
    text-transform: uppercase;
    font-family: "GloberBold", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 0;
    border: 0;
  }

  .btn-light {
    background: white;
    color: black;
    font-size: $font-size-base * 0.89 * 0.75;
    @include media-query($medium-up) {
      font-size: $font-size-base * 0.89;
    }
    &:hover {
      color: white;
      background: lighten(black, 10);
    }
  }
}

.Blog__buttons--white-hover-bg-dark {
  background: white;
  color: black;
  font-size: 16px;
  border-radius: 0;
  border: 1px solid white;
  &:hover {
    border: 1px solid black;
  }
  &:focus {
    border: 1px solid black;
  }
}

.blog-image-wrapper .right-0 {
  right: 0px;
}
.blog-image-wrapper .top-10 {
  top: 10px;
}

$color: #000000;

.video {
  position: relative;
  width: 140%;
  margin-left: -20%;
}

body {
  overflow-x: hidden;
}

.blog-article {
  & .full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    & img {
      width: 100%;
    }
  }
}

#blog-category-nav {
  z-index: 20;
  max-height: 100vh;
  font-family: 'GloberBold', Arial, Helvetica, sans-serif;
  #navbarToggleExternalContent { overflow: auto;}
  div { background: black; ul { overflow: auto; height: 90.5vh; }}
  .navbar {
    min-height: 62px;
    background: black;
    &-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
    &-toggler, button {
      &:focus { outline: 0 !important; }
    }
  }
}

.blog-back-to-top {
  display: none;
  right: 50px;
  bottom: 5%;
  background-color: white;
  z-index: 2;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.25) !important;
  cursor: pointer;
}

@media screen and (max-width: 360px) {
  .blogIndex-hero, .blogIndex-hero-overlay {
    min-height: 600px;
  }
}

