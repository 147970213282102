

.ProductList {
  display: flex; 
  justify-content: space-between !important;
  flex-wrap: wrap;
  @include media-query($medium-up) {
    justify-content: center !important;
    margin-left: -6rem !important;
    margin-right: -6rem !important;
    flex-wrap: unset;
  }
}

.Product .swiper-wrapper{
  height: 60px;
}

img.lazy.img-fluid.entered.loaded{
  margin-top: 0 !important;
}
