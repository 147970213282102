.icon-social{
  &-desktop{
    padding-top: 23px;
    text-align: center;
    white-space: nowrap;
   
    @include media-query($medium-up) {
      padding-bottom: 30px;
    }
   
    a.social-icon{
      width: 20px;
      padding: 1px;
      display: inline-block;
      margin: 0 4%;
      .cls-3, .st0{
        fill:#848484;
      }
      &:hover{
        .cls-3, .st0, path{
          fill: white;
        }
      }
      &:nth-child(6) {
        margin-right: 0;
      }
    }
  }
}

.col-sm-12 {
  .icon-social {
    &-desktop {
      display: block;
      padding-top: 0;

      a.social-icon {
        .cls-3, .st0 {
          fill:#000;
        }
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
}
