.btn-center {
  margin-right: auto;
  margin-left: auto;
}

p {
  a, a:link, a:visited {
    &.underline {
      color: #1f1f1f;
      text-decoration: underline;
    }
  }
}

a, a:link, a:visited {
  &.underline {
    color: $color-black;
    text-decoration: underline;
    &.color-alert {
      color: $color-error;
    }
    &.color-warn {
      color: $color-warn;
    }
  }
}

.btn {
  padding: 8px 22px 5px 22px;
  border-radius: 0px;
  margin: 0 5px;
  &.active {
    font-family: "GloberBold", Arial, Helvetica, sans-serif;
    color: $color-link;
    .text-right & { padding-right: 0; }
    &.small { font-family: "GloberRegular", Arial, Helvetica, sans-serif; }
  }
  &.first { padding-left: 0; }
}

.btn-main {
  background: $color-link;
  color: white;
  text-transform: uppercase;
  font-family: "GloberBold", Arial, Helvetica, sans-serif;
  line-height: 1.5;

  &:hover {
    color: white;
    background: lighten($color-link, 10);
  }
  &:focus {
    color: white;
  }

  &[disabled] {
    color: darken(#cbcbcb, 10);
    background: lighten(#1f1f1f, 10);
  }
}

.btn {
  text-decoration: none;
  &_white { color: white; }
}

.text-center .btn-primary {
  width: auto;
  min-width: 150px;
  padding: 8px 10px 5px 10px;
  font-size: 16px;
  opacity: 1;
  transition: 0.3s;

}

.text-center .btn-primary:hover { opacity: 0.8; }

.btn-primary {
  background: black;
  color: white;
  text-transform: uppercase;
  font-family: "GloberBold", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;

  &_white {
    background: white;
    color: black;
  }
  &:focus { color: white; }
  &:hover {
    color: white;
    background: lighten(#1f1f1f, 10);
  }
  &_white:hover {
    color: #1f1f1f;
    background: lighten(white, 10);
  }
  &_white:focus { color: #1f1f1f; }
}

.btn-secondary {
  background: $color-light-slate;
  color: white;
  text-transform: uppercase;
  line-height: 1.5;
  &:hover {
    color: white;
    background: lighten($color-light-slate, 10);
  }

  &:focus { color: white; }
}

.ys-black { color: black; }
.ys-black { color: white; }
.ys-black-underline-btn {
  color: black;
  border-bottom: 2px solid black;
  padding-bottom: 2px;
  transition: all 0.5s;
  &:hover {
    color: $color-link;
    border-bottom: 2px solid $color-link;
  }
}
.ys-white-underline-btn {
  color: white;
  border-bottom: 2px solid white;
  padding-bottom: 2px;
  transition: all 0.5s;
  &:hover {
    color: $color-link;
    border-bottom: 2px solid $color-link;
  }
}
.underline-btn { text-decoration: underline; }

.btn-alert {
  background: $color-error;
  color: white;
  line-height: 1.5;
  &:hover {
    color: white;
    background: lighten($color-error, 10);
  }

  &:focus { color: white; }
}

.btn-warn {
  @extend .btn-alert;
  background: $color-contrast;
}

button {
  &.close {
    font-family: initial;
    font-weight: 100;
    font-size: 23px;
    color: black;
    opacity: 0.7;
  }
}

.button-group {
  .btn {
    width: calc(50% - 2px);
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    display: inline-block;
  }
}

// Reset button appearance to remove default browser chrome
.button-reset {
  appearance: none;
  background: unset;
  border: unset;
}
