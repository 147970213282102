// Footer
.navbar { margin-bottom: 0 !important; }

#product-page {
  .breadcrumbs { @extend .col-md-12; }
}

.hidden { @extend .d-none; }

#footer {
  .footer-col-4 { @extend .col-sm-3; }
  .footer-col-container { @extend .col-sm-12; }
  .footer-credits { @extend .col-sm-12; }
}

.social {
  &-desktop { @extend .d-none; }
  &-mobile { @extend .d-block; }
}

// Mobile view
@media only screen and (max-width: $max-width-mobile) {
  #footer {
    ul { margin-left: 10px; }
    .footer-credits {
      margin: 20px 0;
      &-content {
        &--extra { text-align: center; }
      }
    }
  }
  #filters {
    width: 100%;
    position: relative;
  }
}

// z-index placements
.z-1 { z-index: 1; }
.z-2 { z-index: 2; }

// Usable position for absolute
.top-0 { top: 0; }
.left-0 { left: 0; }
.right-0 { right: 0; }
.bottom-0 { bottom: 0; }
