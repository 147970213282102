@font-face {font-family: 'GloberBlackItalic';font-display: swap;src: url('~Fonts/2A0FEB_0_0.eot');src: url('~Fonts/2A0FEB_0_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_0_0.woff2') format('woff2'),url('~Fonts/2A0FEB_0_0.woff') format('woff'),url('~Fonts/2A0FEB_0_0.ttf') format('truetype');font-weight: normal; font-style: normal;} @font-face {font-family: 'GloberBook';font-display: swap;src: url('~Fonts/2A0FEB_1_0.eot');src: url('~Fonts/2A0FEB_1_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_1_0.woff2') format('woff2'),url('~Fonts/2A0FEB_1_0.woff') format('woff'),url('~Fonts/2A0FEB_1_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberBoldItalic';font-display: swap;src: url('~Fonts/2A0FEB_2_0.eot');src: url('~Fonts/2A0FEB_2_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_2_0.woff2') format('woff2'),url('~Fonts/2A0FEB_2_0.woff') format('woff'),url('~Fonts/2A0FEB_2_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberBold';font-display: swap;src: url('~Fonts/2A0FEB_3_0.eot');src: url('~Fonts/2A0FEB_3_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_3_0.woff2') format('woff2'),url('~Fonts/2A0FEB_3_0.woff') format('woff'),url('~Fonts/2A0FEB_3_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberBookItalic';font-display: swap;src: url('~Fonts/2A0FEB_4_0.eot');src: url('~Fonts/2A0FEB_4_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_4_0.woff2') format('woff2'),url('~Fonts/2A0FEB_4_0.woff') format('woff'),url('~Fonts/2A0FEB_4_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberBlack';font-display: swap;src: url('~Fonts/2A0FEB_5_0.eot');src: url('~Fonts/2A0FEB_5_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_5_0.woff2') format('woff2'),url('~Fonts/2A0FEB_5_0.woff') format('woff'),url('~Fonts/2A0FEB_5_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberHeavy';font-display: swap;src: url('~Fonts/2A0FEB_6_0.eot');src: url('~Fonts/2A0FEB_6_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_6_0.woff2') format('woff2'),url('~Fonts/2A0FEB_6_0.woff') format('woff'),url('~Fonts/2A0FEB_6_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberHeavyItalic';font-display: swap;src: url('~Fonts/2A0FEB_7_0.eot');src: url('~Fonts/2A0FEB_7_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_7_0.woff2') format('woff2'),url('~Fonts/2A0FEB_7_0.woff') format('woff'),url('~Fonts/2A0FEB_7_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberLight';font-display: swap;src: url('~Fonts/2A0FEB_8_0.eot');src: url('~Fonts/2A0FEB_8_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_8_0.woff2') format('woff2'),url('~Fonts/2A0FEB_8_0.woff') format('woff'),url('~Fonts/2A0FEB_8_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberLightItalic';font-display: swap;src: url('~Fonts/2A0FEB_9_0.eot');src: url('~Fonts/2A0FEB_9_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_9_0.woff2') format('woff2'),url('~Fonts/2A0FEB_9_0.woff') format('woff'),url('~Fonts/2A0FEB_9_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberRegularItalic';font-display: swap;src: url('~Fonts/2A0FEB_A_0.eot');src: url('~Fonts/2A0FEB_A_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_A_0.woff2') format('woff2'),url('~Fonts/2A0FEB_A_0.woff') format('woff'),url('~Fonts/2A0FEB_A_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberRegular';font-display: swap;src: url('~Fonts/2A0FEB_B_0.eot');src: url('~Fonts/2A0FEB_B_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_B_0.woff2') format('woff2'),url('~Fonts/2A0FEB_B_0.woff') format('woff'),url('~Fonts/2A0FEB_B_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberSemiBoldItalic';font-display: swap;src: url('~Fonts/2A0FEB_C_0.eot');src: url('~Fonts/2A0FEB_C_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_C_0.woff2') format('woff2'),url('~Fonts/2A0FEB_C_0.woff') format('woff'),url('~Fonts/2A0FEB_C_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberSemiBold';font-display: swap;src: url('~Fonts/2A0FEB_D_0.eot');src: url('~Fonts/2A0FEB_D_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_D_0.woff2') format('woff2'),url('~Fonts/2A0FEB_D_0.woff') format('woff'),url('~Fonts/2A0FEB_D_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberThin';font-display: swap;src: url('~Fonts/2A0FEB_E_0.eot');src: url('~Fonts/2A0FEB_E_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_E_0.woff2') format('woff2'),url('~Fonts/2A0FEB_E_0.woff') format('woff'),url('~Fonts/2A0FEB_E_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberThinItalic';font-display: swap;src: url('~Fonts/2A0FEB_F_0.eot');src: url('~Fonts/2A0FEB_F_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_F_0.woff2') format('woff2'),url('~Fonts/2A0FEB_F_0.woff') format('woff'),url('~Fonts/2A0FEB_F_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberxBoldItalic';font-display: swap;src: url('~Fonts/2A0FEB_10_0.eot');src: url('~Fonts/2A0FEB_10_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_10_0.woff2') format('woff2'),url('~Fonts/2A0FEB_10_0.woff') format('woff'),url('~Fonts/2A0FEB_10_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}
@font-face {font-family: 'GloberxBold';font-display: swap;src: url('~Fonts/2A0FEB_11_0.eot');src: url('~Fonts/2A0FEB_11_0.eot?#iefix') format('embedded-opentype'),url('~Fonts/2A0FEB_11_0.woff2') format('woff2'),url('~Fonts/2A0FEB_11_0.woff') format('woff'),url('~Fonts/2A0FEB_11_0.ttf') format('truetype'); font-weight: normal; font-style: normal;}

#ysslider, .medium-editor-element{
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: none !important;
  }
}

h1, .h1 {
  font-family: 'GloberxBold';
  font-size: 64px;
  text-transform: uppercase;
}

h2, .h2 {
  font-family: 'GloberxBold';
  font-size: 44px;
  text-transform: uppercase;
}

h3, .h3 {
  font-family: 'GloberxBold';
  font-size: 36px;
  text-transform: uppercase;
}

h4, .h4 {
  font-family: 'GloberSemiBold';
  font-size: 36px;
  text-transform: uppercase;
}

h5, .h5 {
  font-family: 'GloberBold';
  font-size: 26px;
  text-transform: uppercase;
  &.light { font-family: 'GloberSemiBold' !important; }
  &.regular { font-family: 'GloberRegular'; }
}

h6, .h6 {
  font-family: 'GloberSemiBold';
  font-size: 14px;
}

p {
  font-family: 'GloberRegular';
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

body {
  font-family: 'GloberRegular';
  font-size: 14px;
  line-height: 20px;
}

.small { font-size: 11px; }

p {
  &.body-medium {
    font-size: 16px;
    line-height: 28px;
  }
  &.body-large {
    font-size: 22px;
    line-height: 34px;
  }

  &.body-small {
    font-size: 12px;
    line-height: 1.5;
  }
}
